import {
    ADD,
    firebase,
    db,
} from '@/firebase.js'

import Loading from '@/components/Loading/Loading.vue'

export default {
    name: 'Auth',
    components: {
        Loading
    },
    data: function () {
        return {
            activeStep: 0,
            auth: null,
            email: null,
            password: null,
            userIsRegistrered: null,
            showLoadingOverlay: false,
            inputMessage: null,
            passwordResetSent: false,
            steps: [{
                title: 'Email',
                img: 'email.svg',
                submit: this.setEmail,
                inputs: [{
                    tooltip: 'Recommended not to use your school email',
                    placeholder: 'Email',
                    type: 'email',
                }]
            }, {
                title: 'Password',
                img: 'password.svg',
                submit: this.passwordSubmit,
                inputs: [{
                    tooltip: 'Atleast 6 characters',
                    placeholder: 'Password',
                    type: 'password',
                    hiddenInput: true,
                }]
            }],
            verifyEmailSent: false
        }
    },
    methods: {
        toggleInputVisibility: function (input) {
            if (input.showValue === null) input.showValue = true
            else input.showValue = !input.showValue
            this.$forceUpdate()
        },
        addQuery: function (obj) {
            const query = {
                ...this.$route.query,
                ...obj
            };
            this.$router.replace({
                query
            });
        },
        setStep: function (dir) {
            this.inputMessage = null
            if (parseInt(dir) < 0 && this.activeStep === 0) {
                let origin = this.$route.query.origin
                this.$router.replace({
                    path: '/' + origin,
                    query: {
                        step: null
                    },
                })
            } else {
                document.getElementById('auth-button').blur()
                this.activeStep += parseInt(dir)
                this.addQuery({
                    step: this.activeStep
                })
            }
        },
        setEmail: function () {
            this.email = this.steps[this.activeStep].inputs[0].val
            this.setStep(1)
            this.addQuery({
                email: this.email
            })
        },
        checkIfUserExists: function () {
            return new Promise(resolve => {
                let getUserExistence = firebase.functions().httpsCallable('getUserExistence');
                getUserExistence({
                    email: this.email
                }).then(result => {
                    console.log(result)
                    resolve(result.data.exists)
                });
            })
        },
        passwordSubmit: async function () {
            this.showLoadingOverlay = true
            let userExists = await this.checkIfUserExists()
            this.password = this.steps[this.activeStep].inputs[0].val
            if (userExists) this.signIn()
            else this.createUser()
        },
        signIn: function () {
            console.log(this.email)
            firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
                console.log('Sign in successful')
                this.$router.replace({
                    path: (this.$event && this.$event.eid) ? ('/' + this.$event.eid) : '/'
                })
            }).catch(error => {
                this.showLoadingOverlay = false
                if (error.code === 'auth/wrong-password') {
                    this.inputMessage = 'Invalid password 😢'
                } else if (error.message === 'There is no user record corresponding to this identifier. The user may have been deleted.') {
                    this.createUser()
                } else {
                    this.inputMessage = error.message
                }
            });
        },
        createUser: function () {
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(() => this.addUserToDB()).catch((error) => {
                console.log(error)
                this.showLoadingOverlay = false
                if (error.message === 'The email address is already in use by another account.') this.signIn()
                else this.inputMessage = error.message
            });
        },
        resetPassword: function () {
            if (this.passwordResetSent) return
            firebase.auth().sendPasswordResetEmail(this.email).then(() => {
                // Email sent.
                this.passwordResetSent = true
            }).catch(error => {
                // An error happened.
                alert(error)
            });
        },
        addUserToDB: function () {
            let user = firebase.auth().currentUser;
            let userObj = {
                email: this.email,
                verified: false,
                auth: (this.auth) ? this.auth : 'email',
                uid: user.uid,
                date: new Date(),
                origin: 'app'
            }
            let col = db.collection('users')
            ADD(col, userObj).then(doc => {
                console.log('User is added to db')
                user.updateProfile({
                    displayName: doc.id,
                })
                this.showLoadingOverlay = false
                this.showVerification()
            })
        },
        showVerification: function () {
            this.verifyEmailSent = true
            this.$router.push({
                query: {
                    signedup: true
                }
            })
        }
    },
    created: function () {
        this.auth = this.$route.query.auth
        if (this.$route.query.email) this.email = this.$route.query.email
        if (this.$route.query.uid) {
            this.showVerification()
        } else {
            if (this.$route.query.step) this.activeStep = parseInt(this.$route.query.step)
        }
    }
}